@charset "UTF-8";

$icon-base-url: 'https://claasconnectassets.blob.core.windows.net/frontend/fonts/icons/';

@font-face {
  font-family: "cde-icons";
  src:url($icon-base-url + "cde.eot");
  src:url($icon-base-url + "cde.eot?#iefix") format("embedded-opentype"),
    url($icon-base-url + "cde.woff") format("woff"),
    url($icon-base-url + "cde.ttf") format("truetype"),
    url($icon-base-url + "cde.svg#cde") format("svg");
  font-weight: normal;
  font-style: normal;
}

[data-icon]:before {
  font-family: "cde-icons" !important;
  content: attr(data-icon);
  font-style: normal !important;
  font-weight: normal !important;
  font-variant: normal !important;
  text-transform: none !important;
  speak: none;
  line-height: 1;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

[class^="icon-"]:before,
[class*=" icon-"]:before {
  font-family: "cde-icons" !important;
  font-style: normal !important;
  font-weight: normal !important;
  font-variant: normal !important;
  text-transform: none !important;
  speak: none;
  line-height: 1;
  color: $color-background-alt-dark;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}


.icon-engine-state:before {
  content: "\61";
}
.icon-max-speed:before {
  content: "\62";
}
.icon-fuel-tank-level:before {
  content: "\63";
}
.icon-satellite:before {
  content: "\64";
}
.icon-001046-23:before {
  content: "\65";
}
.icon-printer:before {
  content: "\66";
}
.icon-key:before {
  content: "\67";
}
.icon-operating-time:before {
  content: "\68";
}
.icon-service-tool:before {
  content: "\69";
}
.icon-007841-23:before {
  content: "\6a";
}
.icon-close:before {
  content: "\6b";
}
.icon-monitor:before {
  content: "\6c";
}
.icon-017325-24:before {
  content: "\6d";
}
.icon-claas-connect:before {
  content: "\6e";
}
.icon-claas-connect-1:before {
  content: "\6f";
}
.icon-claas-connect-2:before {
  content: "\70";
}
.icon-claas-connect-3:before {
  content: "\71";
}
.icon-claas-connect-4:before {
  content: "\72";
}
.icon-claas-connect-5:before {
  content: "\73";
}
.icon-claas-connect-6:before {
  content: "\74";
}
.icon-claas-connect-7:before {
  content: "\75";
}
.icon-claas-connect-8:before {
  content: "\76";
}
.icon-claas-connect-9:before {
  content: "\77";
}
.icon-claas-connect-10:before {
  content: "\78";
}
.icon-claas-connect-11:before {
  content: "\79";
}
.icon-claas-connect-12:before {
  content: "\7a";
}
.icon-claas-connect-13:before {
  content: "\41";
}
.icon-claas-connect-14:before {
  content: "\42";
}
.icon-claas-connect-15:before {
  content: "\43";
}
.icon-claas-connect-16:before {
  content: "\44";
}
.icon-claas-connect-17:before {
  content: "\45";
}
.icon-icon-arrow-down:before {
  content: "\46";
}
.icon-icon-configurator:before {
  content: "\47";
}
.icon-icon-pdf:before {
  content: "\48";
}
.icon-speed:before {
  content: "\49";
}
.icon-terminal:before {
  content: "\4a";
}
.icon-claas-connect-18:before {
  content: "\4b";
}
.icon-claas-connect-19:before {
  content: "\4c";
}
.icon-claas-connect-20:before {
  content: "\4d";
}
.icon-claas-connect-21:before {
  content: "\4e";
}
.icon-claas-connect-22:before {
  content: "\4f";
}
.icon-claas-connect-23:before {
  content: "\50";
}
.icon-claas-connect-24:before {
  content: "\51";
}
.icon-claas-connect-25:before {
  content: "\52";
}
.icon-claas-connect-26:before {
  content: "\53";
}
.icon-claas-connect-27:before {
  content: "\54";
}
.icon-claas-connect-28:before {
  content: "\55";
}
.icon-claas-connect-29:before {
  content: "\56";
}
.icon-claas-connect-30:before {
  content: "\57";
}
.icon-claas-connect-31:before {
  content: "\58";
}
.icon-claas-connect-32:before {
  content: "\59";
}
.icon-claas-connect-33:before {
  content: "\5a";
}
.icon-claas-connect-34:before {
  content: "\30";
}
.icon-claas-connect-35:before {
  content: "\32";
}
.icon-claas-connect-36:before {
  content: "\34";
}
.icon-adblue-tank-level:before {
  content: "\31";
}
.icon-fuel:before {
  content: "\33";
}
.icon-alarm:before {
  content: "\35";
}
.icon-vector:before {
  content: "\36";
}
