// DEFINE GLOBAL VARIABLES HERE

// COLORS
$color-background: #fff;
$color-background-inverse: #000;
$color-background-alt-light: #EEE;
$color-background-alt-dark: #696969;
$color-copy: $color-background-inverse;
$color-copy-inverse: $color-background;
$color-copy-fine-print: #999;
$color-button-dark-green: #778410;
$color-link: $color-copy;
$color-link-inverse: $color-copy-inverse;
$color-link-dark: #999;
$color-input-error: #AA0B25;
$color-input-placeholder: #696969;
$color-input-background: $color-background-alt-light;
$color-input-focused-background: #F0F4D1;
$color-input-invalid-background: #FCE5E5;
$color-input-disabled-background: #FDFDFD;
$color-divider: #d7d7d7;
$color-disabled: #ccc;
$color-error: #AA0B25;
$color-info: #E8A900;
$color-success: #00853E;
$color-form-text: #999999;
$color-claas-green: #b3c618;
// new values ?
$color-success-main:#407C4C;
$color-warning-main:#EFCB4B;
$color-error-main:#9E2923;
$color-neutral-main: rgba(0,0,0,.58);

// LAYOUT
$grid-spacing: 20px;
$fixed-layout-height: 80px;
$fixed-layout-padding: 20px;
$authed-layout-padding: 40px;

$icon-size-sticky: 35px;
$icon-size: 35px;



$font-name: "CLAASNeueHelvetica45Light";

$layout-gap: 20px;

//
$color-nav-button-grey: rgba(0, 0, 0, 0.58);



$color-button-green: #B3C618;
$color-button-green-light: #CBDA7C;
$color-button-grey: #E0E0E0;

$component-spacing: 24px;