@import url("https://cdn.fonts.net/t/1.css?apiType=css&projectid=2d22751f-1e6a-434f-aaea-0b4bf9dce64d");
  
@font-face {
  font-family: "CLAASNeueHelvetica35Thin";
  font-style: normal;
  font-stretch: normal;
  font-display: swap;
  src: url('../assets/fonts/CLAASNeueHelvetica/CLAASNeueHelvetica35Thin_normal_normal.woff2') format('woff2'), 
       url('../assets/fonts/CLAASNeueHelvetica/CLAASNeueHelvetica35Thin_normal_normal.woff') format('woff');
}
@font-face {
  font-family: "CLAASNeueHelvetica55Roman";
  font-style: normal;
  font-stretch: normal;
  font-display: swap;
  src:  url('../assets/fonts/CLAASNeueHelvetica/CLAASNeueHelvetica55Roman_normal_normal.woff2') format('woff2'), 
        url('../assets/fonts/CLAASNeueHelvetica/CLAASNeueHelvetica55Roman_normal_normal.woff') format('woff');
}
@font-face {
  font-family: "CLAASNeueHelvetica65Medium";
  font-style: normal;
  font-stretch: normal;
  font-display: swap;
  src:  url('../assets/fonts/CLAASNeueHelvetica/CLAASNeueHelvetica65Medium_normal_normal.woff2') format('woff2'), 
        url('../assets/fonts/CLAASNeueHelvetica/CLAASNeueHelvetica65Medium_normal_normal.woff') format('woff');
}
@font-face {
  font-family: "CLAASNeueHelvetica45Light";
  font-style: normal;
  font-stretch: normal;
  font-display: swap;
  src:  url('../assets/fonts/CLAASNeueHelvetica/CLAASNeueHelvetica45Light_normal_normal.woff2') format('woff2'), 
        url('../assets/fonts/CLAASNeueHelvetica/CLAASNeueHelvetica45Light_normal_normal.woff') format('woff');
}
@font-face {
  font-family: "CLAASNeueHelvetica75Bold";
  font-style: normal;
  font-stretch: normal;
  font-display: swap;
  src:  url('../assets/fonts/CLAASNeueHelvetica/CLAASNeueHelvetica75Bold_normal_normal.woff2') format('woff2'), 
        url('../assets/fonts/CLAASNeueHelvetica/CLAASNeueHelvetica75Bold_normal_normal.woff') format('woff');
}
