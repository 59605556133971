// Utils
@use 'sass:map';
@use '@angular/material' as mat;

@mixin typography($theme) {
    $typography-config: mat.get-typography-config($theme);

    h1 {
      @extend h1;
      font-family: mat.font-family($typography-config);
    }
    
    h2 {
      @extend h2;
      font-family: mat.font-family($typography-config);
    }
    
    h3 {
      @extend h3;
      font-family: mat.font-family($typography-config);
    }
    
    h4 {
      font-family: mat.font-family($typography-config);
    }
    
    h5 {
      font-family: mat.font-family($typography-config);
    }
    
    h6 {
      font-family: mat.font-family($typography-config);
    }
        
}

@import './variables';


h1 {
  font-weight: 400;
  line-height: 125%;
  font-size: 32px;
  letter-spacing: 0.0075em;
}

.cl-h1 {
  font-weight: 400;
  line-height: 125%;
  font-size: 32px;
  letter-spacing: 0.0075em;
}

h2 {
  font-weight: 400;
  line-height: 128.57%;
  font-size: 28px;
  letter-spacing: 0.0089em;
}

.cl-h2 {
  font-weight: 400;
  line-height: 128.57%;
  font-size: 28px;
  letter-spacing: 0.0089em;
}

h3 {
  font-weight: 400;
  line-height: 133.3%;
  font-size: 24px;
}

.cl-h3 {
  font-weight: 400;
  line-height: 133.3%;
  font-size: 24px;
}

h4 {
  $font-weight: 400;
  $line-height: 160%;
  $font-size: 20px;
}

.cl-h4 {
  $font-weight: 400;
  $line-height: 160%;
  $font-size: 20px;
}

h5 {
  font-weight: 500;
  line-height: 160%;
  font-size: 20px;
}

.cl-h5 {
  font-weight: 500;
  line-height: 160%;
  font-size: 20px;
}

h6 {
  font-weight: 400;
  line-height: 144.4%;
  letter-spacing: 0.0084em;
  font-size: 18px;
}

.cl-h6 {
  font-weight: 400;
  line-height: 144.4%;
  letter-spacing: 0.0084em;
  font-size: 18px;  
}

.sub-text {
    color: $color-form-text;
    font-size: 12px;
    font-weight: 300;
    margin-top: 5px;
  }

// universal colors for success-warning-error icons
.icon-color-success::before{
  color: $color-success-main;
}

.icon-color-warning::before{
  color: $color-warning-main;
}

.icon-color-error::before{
  color: $color-error-main;
}

.icon-color-neutral::before{
  color: $color-neutral-main;
}

.bg-color-success::after{
  background-color: $color-success-main;
}

.bg-color-warning::before{
  background-color: $color-warning-main;
}

.bg-color-error::before{
  background-color: $color-error-main;
}

.bg-color-neutral::before{
  background-color: $color-neutral-main;
}


.mat-tab-label-content {   
  font-family: $font-name;
  font-weight: 400;
  font-style: normal;
  font-size: 14px; 
  line-height: 20px;
  letter-spacing: 0.15px;
  color: rba(0, 0, 0, 0.58);
}



/********************************************* 
 * Buttons
 ********************************************/

.primary-button {
  height: 36px;
  border-radius: 4px;
  background-color: $color-claas-green;
  border: 0px;
  padding: 6px 16px 6px 16px;
}

/********************************************* 
 * Material Table Overrides
 ********************************************/

.mat-row:hover { background-color: rgba(179, 198, 24, 0.16); }
.mat-cell {
  font-family: $font-name;
  font-weight: 400;
  font-style: normal;
  font-size: 14px !important; 
  line-height: 20px;
  letter-spacing: 0.15px;
  color: #202020 !important;
}
.mat-header-cell {
  font-family: $font-name;
  font-weight: 400 !important;
  font-style: normal;
  font-size: 14px !important; 
  line-height: 20px;
  letter-spacing: 0.15px !important;
  text-transform: capitalize;
  color: #202020 !important;
}



.mat-horizontal-stepper-header { padding: 0px !important; }

.mat-horizontal-content-container { padding: 0px 0px 24px 0px !important; }

.snackbar-success { background-color: $color-claas-green !important;  }
.snackbar-failure { background-color: $color-error-main !important; }

.cl-fo-table-button {
  background-color: #ffffff;
  border-radius: 4px;
  height: 30px;
  font-size: 13px;
  line-height: 22px;
  font-style: normal;
  font-weight: 500;
  font-family: Roboto;
  border: 1px solid #B3C618; 
  color: #B3C618;
}


.page-layout-unbounded {
  width: 100% !important;
  min-width: 100% !important;
}

.page-layout-bounded {
  //  width: 1044px !important;
  //  max-width: 1044px !important;
   width: 1544px !important;
   max-width: 1544px !important;
   padding: $component-spacing;
   margin-left: auto;
   margin-right: auto;
}


.page-layout {
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: stretch;
  //margin: $layout-gap;
  // width: 100%;
  // min-width: 100%;
  //padding: $component-spacing;
}

.page-with-background {
  background-color: #fff;
}



.cl-flex-row {
  display: flex;
  flex-direction: row;
  // justify-content: flex-start;
  // align-items: flex-start;
}



.cl-flex-row > *{
  width: 100%;
  &:not(:last-child) {
    margin-right: $component-spacing;
  }

}

.cl-flex-start-start {
  justify-content: flex-start;
  align-items: flex-start;
}

.cl-flex-start-center {
  justify-content: flex-start;
  align-items: center;
}

.cl-flex-start-end {
  justify-content: flex-start;
  align-items: flex-end;
}

.cl-flex-center-center {
  justify-content: center;
  align-items: center;
}

.cl-flex-end-start {
  justify-content: flex-end !important;
  align-items: flex-start !important;
}

.cl-flex-end-center {
  justify-content: flex-end !important;
  align-items: center !important;
}

.cl-dialog {
  padding: 24px;
}




// .cl-flex-element {
//   width: 100%;
//   &:not(:last-child) {
//     margin-right: $component-spacing;
//   }
// }

.cl-flex-wrap {
  flex-wrap: wrap;
}

.cl-flex-nowrap {
  flex-wrap: nowrap;
}

// uppercase labels of tab labels
.mdc-tab { text-transform: uppercase !important; }

.mat-mdc-menu-panel {
  max-width: none !important;
}


TR.table-row-selected {
  background-color: #EAEDD2 !important;
}

TR.table-row:hover {
  background-color: #EAEDD2 !important;
}